import { API } from 'aws-amplify';
import { get, isArray, set } from 'lodash';
import {
  API_CREATE_ORDER,
  API_SUBSCRIPTION_EXISTING_USER,
} from '../constants/apis';
import getFlags from './features';

export const CREATE_ORDER_START = `coupon/CREATE_ORDER/START`;
export const CREATE_ORDER_SUCCESS = `coupon/CREATE_ORDER/SUCCESS`;
export const CREATE_ORDER_FAILURE = `coupon/CREATE_ORDER/FAILURE`;

export const ORDER_RESET = `coupon/ORDER_RESET/START`;

export const VALIDATE_COUPON_START = `coupon/VALIDATE_COUPON/START`;
export const VALIDATE_COUPON_SUCCESS = `coupon/VALIDATE_COUPON/SUCCESS`;
export const VALIDATE_COUPON_FAILURE = `coupon/VALIDATE_COUPON/FAILURE`;

const createOrder =
  ({ offerId, coupon, platformType }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { systemConfig } = state;
    const getFeatureFlags = getFlags({
      systemConfig,
    });
    const isSelfServeOffers = getFeatureFlags(`SELF_SERVE_OFFERS`);
    const body = { coupon };
    if (isSelfServeOffers) {
      const osOfferId =
        isArray(offerId) && offerId?.length ? offerId[0] : offerId;
      set(body, 'osOfferId', osOfferId);
      set(body, 'platformType', platformType || 'web');
      set(body, 'paymentGateway', 'creditCard');
    } else {
      set(body, 'items', offerId);
    }

    dispatch({
      type: CREATE_ORDER_START,
      data: coupon,
    });
    const params = {
      headers: {},
      body,
    };
    return API.post(API_CREATE_ORDER, '', params)
      .then((response) => {
        const { order } = response;
        dispatch({
          type: CREATE_ORDER_SUCCESS,
          data: {
            response: order,
            coupon: coupon,
          },
        });
      })
      .catch((err) => {
        const error = get(err, ['response', 'data'], err);
        dispatch({
          type: CREATE_ORDER_FAILURE,
          data: {
            response: error,
          },
        });
      });
  };

const resetOrder = () => async (dispatch) => {
  dispatch({
    type: ORDER_RESET,
  });
};

// TODO: ADD NEW EVENT
/**
 triggerGoogleAnalyticsEvent(
  'voucher',
  'login',
  'error(ineligible))'
 */

const checkEligibility = ({ userId, coupon }) => {
  return (dispatch) => {
    const params = {
      headers: {},
    };
    if (!userId) {
      dispatch({
        type: VALIDATE_COUPON_FAILURE,
        data: null,
      });
      return;
    }
    dispatch({ type: VALIDATE_COUPON_START });
    const path = userId
      ? `users/${userId}/vouchers/${coupon}`
      : `vouchers/${coupon}`;
    const endpoint = API_SUBSCRIPTION_EXISTING_USER;

    API.get(endpoint, `/${path}/eligibility`, params)
      .then((response) => {
        const {
          data: { item },
        } = response;
        if (item) {
          const { eligibility: isValid } = item;
          if (isValid) {
            dispatch({
              type: VALIDATE_COUPON_SUCCESS,
              data: item,
            });
          } else {
            dispatch({
              type: VALIDATE_COUPON_FAILURE,
              data: item,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        const error = get(err, ['response', 'data'], err);
        dispatch({
          type: VALIDATE_COUPON_FAILURE,
          data: error,
        });
      });
  };
};

const checkAndCreateOrder = ({ userId, coupon, offerId, platformType }) => {
  return (dispatch) => {
    const params = {
      headers: {},
    };
    dispatch({ type: VALIDATE_COUPON_START });
    const path = userId
      ? `users/${userId}/vouchers/${coupon}`
      : `vouchers/${coupon}`;
    const endpoint = API_SUBSCRIPTION_EXISTING_USER;

    API.get(endpoint, `/${path}/eligibility`, params)
      .then((response) => {
        const {
          data: { item },
        } = response;
        if (item) {
          const { eligibility: isValid } = item;
          if (isValid) {
            dispatch({
              type: VALIDATE_COUPON_SUCCESS,
              data: item,
            });
            dispatch(
              createOrder({
                offerId,
                coupon,
                platformType,
              }),
            );
          } else {
            dispatch({
              type: VALIDATE_COUPON_FAILURE,
              data: item,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        const error = get(err, ['response', 'data'], err);
        dispatch({
          type: VALIDATE_COUPON_FAILURE,
          data: error,
        });
      });
  };
};

const initialState = {
  loading: false,
  error: null,
  couponData: null,
  orderData: null,
  prevOrderData: null,
  couponValue: '',
  eligibility: null,
};

const couponReducer = (oldState = initialState, action) => {
  const state = JSON.parse(JSON.stringify(oldState));
  const { data, type } = action;
  switch (type) {
    case VALIDATE_COUPON_START: {
      return {
        ...state,
        loading: true,
        error: false,
        eligibility: null,
      };
    }
    case VALIDATE_COUPON_SUCCESS: {
      return {
        ...state,
        loading: false,
        couponData: data,
        error: false,
        eligibility: true,
      };
    }
    case VALIDATE_COUPON_FAILURE: {
      return {
        ...state,
        loading: false,
        couponData: data || { description: type },
        error: true,
        eligibility: false,
      };
    }
    case CREATE_ORDER_START: {
      return {
        ...state,
        loading: true,
        couponValue: data,
        prevOrderData: null,
      };
    }
    case CREATE_ORDER_SUCCESS: {
      const dataResponse = data?.response || [];
      const dataCoupon = data?.coupon || '';
      const orderData = {
        ...dataResponse,
        couponValue: dataCoupon,
      };

      return {
        ...state,
        loading: false,
        orderData: orderData,
        prevOrderData: orderData,
        couponValue: dataCoupon,
      };
    }
    case CREATE_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        orderData: null,
        prevOrderData: null,
        error: data?.response,
      };
    }
    case ORDER_RESET: {
      return {
        ...state,
        loading: false,
        error: null,
        couponData: null,
        orderData: null,
        couponValue: '',
        eligibility: null,
      };
    }
    default:
      return state;
  }
};

export { createOrder, checkAndCreateOrder, checkEligibility, resetOrder };
export default couponReducer;
